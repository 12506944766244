<template>
	<div class="profile-picture-upload-wrapper">
		<w-photo-upload title="Upraviť profilovú fotku" url="v1/auth/info" :image="image" noPhotoText="Profilová fotka nieje nastavená"></w-photo-upload>
	</div>
</template>

<script>

export default {
	components: {
		'w-photo-upload': () => import('@/plugins/w@component/photoUpload/w-photo-upload.vue')
	},

	data() {
		return {
			image: null
		}
	},
	
	async created() {
		const user = await this.$store.dispatch('wAuth/userInfo')
		user.avatar && user.avatar.path ?  this.image = user.avatar.path : this.image = null
	}
}
</script>

<style lang="scss">
    .profile-picture-upload-wrapper {
        .image-preview {
            height: 100px;
            width: 100px;
            object-fit: cover;
            border-radius: 100%;
        }
    }    
</style>
